<script setup>
</script>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import moment from 'moment';
import deliveryService from "@/services/api/delivery.js";
import * as Socket from '../../plugins/socket_client';
import MultiSelect from 'primevue/multiselect';
export default {
  components: {
    Button,
    Dialog,
    MultiSelect
  },
  props: ["filters", "loadingLocationsAvailable"],
  data: function () {
    return {
      deliveries: [],
      myDelivery: null,
      showGoBackModal: false,
      user: null
    }
  },
  watch: {
    'filters.loadingLocations': function() {
      this.filterByLoadingLocation();
    },
  },
  mounted() {
    this.getDeliveries(this.user);
    Socket.add("truckLoading", this.user.plant.code);
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    const socket = Socket.connect();
    socket.on("truckLoading", this.onEventHandler);
    Socket.add("truckLoading", this.user.plant.code)
  },
  methods: {
    onEventHandler(data) { // WebShocket event listener
      console.log("onEventHandler", data);
      if (typeof data.data.delivery !== 'undefined') {
        if (data.plantCode == this.user.plant.code && data.data.delivery.status == 'Loading') {
          this.insertNewDelivery(data.data.delivery);
        }
        else if (data.data.delivery.status != 'Loading') {
          this.removeLoadedDelivery(data.data.delivery);
        }
      }
    },
    removeLoadedDelivery(loadedDelivery) { // Remove incoming delivery from ws
      let matches = this.deliveries.filter(function (data) { return data.delivery_id == loadedDelivery._id });
      if (matches.length != 0) {
        this.deliveries.splice(this.deliveries.findIndex(function (data) { return data.delivery_id == loadedDelivery._id }), 1);
      }
    },
    insertNewDelivery(newDelivery) { // Insert incoming delivery from ws 
      let matches = this.deliveries.filter(function (data) { return data.delivery_id == newDelivery._id });
      if (matches.length == 0) {
        this.createDelivery(newDelivery, newDelivery._id);
      }
    },
    createDelivery(delivery, deliveryId) { // Splits deliveries by material in multiple cards
      let materials = delivery.material;
      let checkTransitStatus = (i) => {
        if(!(delivery.material[i].transitStatus != undefined && delivery.material[i].transitStatus == 'Complete')){
          this.deliveries.push(this.formatDelivery(delivery, deliveryId, materials.length, i));
        }
      };
      materials.forEach((e, i) => checkTransitStatus(i));
    },
    formatDelivery(delivery, id, split_load, material_index) {
      if(delivery.material[material_index].loadingLocationDescription != undefined) {
        this.addItemToLoadingLocations(delivery.material[material_index].loadingLocationDescription);
      }
      return {
        'delivery_id': id,
        'loading_time': moment(delivery.Tour.planned.unloadingLocationEntered).format("HH:mm"),
        'transit_status': delivery.material[material_index].transitStatus != undefined ? delivery.material[material_index].transitStatus : 'Waiting',
        'loading_location': {
          'description': delivery.material[material_index].loadingLocationDescription != undefined ? delivery.material[material_index].loadingLocationDescription : '',
        },
        'material': {
          'description': delivery.material[material_index].descriptions[0].text,
          // 'weigth': delivery.material[material_index].baseUoMquantity,
          'weigth': delivery.logistic.truck.mwa - (delivery.firstWeight.weight / 1000),
          'uom': delivery.material[material_index].baseUoM != '' ? delivery.material[material_index].baseUoM : 'TNE'
        },
        'vehicle': {
          'plate': delivery.logistic.truck.plateNumber,
          'haulier': delivery.logistic.carrier.name
        },
        'split_load': split_load,
        'material_index': material_index,
        'visible': true,
        'highlighted': false
      }
    },
    getDeliveries(user) { // Get all 'Loading' deliveries for specified plant
      deliveryService.getLoadingDeliveries(user).then((response) => {
        response.data.data.forEach((d) => this.createDelivery(d._source, d._id));
      })
    },
    changeStatus(status, id, materialIndex) { // Changes transit status
      this.setDeliveryStatus(id, status, materialIndex);
      let index = this.deliveries.findIndex((delivery) => delivery.delivery_id == id && delivery.material_index == materialIndex);
      this.deliveries[index].transit_status = status;
      if (this.deliveries[index].transit_status == 'Complete') {
        this.deliveries.splice(index, 1);
      }
    },
    goBack(status) { // Changes transit status to a previous state
      this.showGoBackModal = false;
      this.changeStatus(status, this.myDelivery.delivery_id, this.myDelivery.material_index);
    },
    prepareGoBackModal(deliveryId, materialIndex) {
      this.myDelivery = this.deliveries.find((delivery) => delivery.delivery_id == deliveryId && delivery.material_index == materialIndex);
      if(this.myDelivery.transit_status == 'Loading')
        this.showGoBackModal = true;
    },
    setDeliveryStatus(deliveryId, status, materialIndex) { // Sends selected transit status to outbound
      deliveryService.setDeliveryTransitStatus(this.user, deliveryId, status, materialIndex).then((response) => {
        this.$notify({
          title: this.$t("Delivery Status - " + status),
          type: "success"
        })
      }).catch(err => {
        this.$notify({
          title: this.$t("Error, unable to change status"),
          type: "error",
          duration: -1,
          closeOnClick: true
        })
      })
    },
    highlightThisTruck(plateNumber) {
      let changeStatus = (d) => {
        if(d.vehicle.plate == plateNumber) {
          d.highlighted = !d.highlighted;
        }
        else{
          d.highlighted = false;
        }
        return d;
      }
      this.deliveries = this.deliveries.map(changeStatus);
    },
    addItemToLoadingLocations(location) {
      let alreadyExist = this.loadingLocationsAvailable.find((ll) => ll.code == location);
      if(alreadyExist == undefined){
        this.loadingLocationsAvailable.push({name: location, code: location});
      }
    },
    filterByLoadingLocation() {
      let filters = this.filters.loadingLocations.map((f) => f.code);
      let changeStatus = (d) => {
        d.visible = filters.includes(d.loading_location.description)
        return d;
      }
      this.deliveries = this.deliveries.map(changeStatus);
    }
  }
}
</script>

<template>
  <div class="app-content data-list orders" style="height: 100%;">

    <div v-if="deliveries.length == 0" class="empty-list">
      <i class="zmdi zmdi-truck zmdi-hc-4x"></i>
      <div>{{ $t('No trucks left to load') }}</div>
    </div>

    <div v-if="deliveries.every((d) => !d.visible) && deliveries.length != 0" class="empty-list">
      <i class="zmdi zmdi-pin zmdi-hc-4x"></i>
      <div>{{ $t('No matches for selected filters') }}</div>
    </div>

    <div v-for="delivery in deliveries" class="data-list__table row__orderList row" :style="delivery.visible ? '' : 'display: none'">
      <div class="columns">
        <div class="column">
          <div class="sholve-card" :style="delivery.highlighted ? 'border-color: black' : 'border-color: white'">
            <div class="sholve-card__material">
              <div>{{ delivery.material.description }}</div>
              <div class="split-load-indicator" v-if="delivery.split_load > 1" @click="highlightThisTruck(delivery.vehicle.plate)">
                <div class="split-load-counter">{{ delivery.split_load }}</div>
                <p>{{ $t('Split Load') }}</p>
              </div>
            </div>
            <div class="sholve-card__weigth">
              {{ delivery.material.weigth + " " + delivery.material.uom + " - " + $t('Load quantity')}}
            </div>
            <div class="sholve-card__plate">
              <span class="plate">
                {{ delivery.vehicle.plate }}
              </span>
            </div>
            <div class="sholve-card__haulier">
              <i class="zmdi zmdi-truck"></i>
              {{ delivery.vehicle.haulier }}
            </div>
            <div class="sholve-card__time">
              <i class="zmdi zmdi-time"></i>
              {{ delivery.loading_time }}
            </div>
            <div class="sholve-card__location">
              <i class="zmdi zmdi-pin"></i>
              {{ delivery.loading_location.description }}
            </div>
            <div class="sholve-card__buttons">
              <div class="row">
                <div class="columns">
                  <div class="column">
                    <Button class="p-button btn btn--primary" label="WAITING"
                      :style="delivery.transit_status == 'Waiting' ? 'background-color: Orange' : 'background-color: black'"
                      @click="prepareGoBackModal(delivery.delivery_id, delivery.material_index)" />
                  </div>
                  <div class="column">
                    <Button class="p-button btn btn--secondary" label="LOADING"
                      :style="delivery.transit_status == 'Loading' ? 'background-color: #49f354' : 'background-color: White'"
                      @click="changeStatus('Loading', delivery.delivery_id, delivery.material_index)" />
                  </div>
                  <div class="column">
                    <Button class="p-button btn btn--secondary" label="COMPLETE"
                      @click="changeStatus('Complete', delivery.delivery_id, delivery.material_index)"
                      :disabled="delivery.transit_status != 'Loading'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog :header="'Revert status'" v-model:visible="showGoBackModal"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '400px' }" :dismissable-mask="true"
      closeOnEscape closable :modal="true">
      <div class="row" style="margin: 20px">
        <div class="columns">
          <div class="column">
            <Button class="p-button btn btn--primary" label="YES" @click="goBack('Waiting')" />
          </div>
          <div class="column">
            <Button class="p-button btn btn--secondary" label="NO" @click="showGoBackModal = false;" />
          </div>
        </div>
      </div>
    </Dialog>

  </div>
</template>

<style lang="scss">

.split-load-counter {
  color: white;
  margin: 0px 5px 0px 0px;
  background-color: black;
  height: 15px;
  padding: 0px 5px 0px 5px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.split-load-indicator {
  border: solid 1px;
  font-size: 12px;
  padding: 0px 5px 0px 5px;
  border-radius: 3px;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.sholve-card {
  border: solid;
  border-color: white;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
  min-height: 11.25rem;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

  &__material {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0 25px;
    border-bottom: 1px solid #EEE;
    font-size: 1.75rem;
    line-height: 1.5rem;
    font-weight: bold;

  }

  &__weigth {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }

  &__plate {
    //background-color: #FFFF00;
    margin: 10px 0px;
    font-size: 1.75rem;
  }

  &__locaion {
    padding-bottom: 10px;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }
}

.plate {
  background-color: #FFFF00;
  border-radius: 4px;
  font-weight: 800;
  border: 2px solid #000;
  padding: 5px 22px;
}
</style>
